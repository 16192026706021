<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header class="d-flex flex-column align-items-start">
            <div class="d-flex justify-content-between w-100">
              <b-card-title>
                Danh sách môn học xét xếp loại tốt nghiệp
              </b-card-title>
              <b-card-title class="mb-0 text-danger">
                Điểm TBTL: {{ totalAveragePoint }}
              </b-card-title>
            </div>
          </b-card-header>
          <b-card-body>
            <vue-good-table
              :columns="registerColumns"
              :rows="dataRows"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'action' && noActions">
                  <b-button
                    v-if="props.row.statusGraduation === null"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click="onDeleteSubject(props.row)"
                  >
                  <feather-icon icon="MinusIcon" />
                </b-button>
                <b-button
                  v-if="props.row.statusGraduation === 0"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="btn-icon"
                  size="sm"
                  @click="onAddSubject(props.row)"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'RegisterSubject',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    VueGoodTable,
  },
  props: {
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        programmeId: null,
        studentClassId: null,
        groupSubjectId: null,
        sort: null,
      },
      trainingSystemId: null,
      currentYear: new Date().getFullYear(),
      totalAveragePoint: 0,
      totalWeightedPoints: 0,
      totalCredits: 0,
      registerColumns: [
        {
          label: 'Môn học',
          field: 'subjectName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Nhóm môn học',
          field: 'groupName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Điểm trung bình môn',
          field: 'averagePoint',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          width: '10%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      paginationOptions: {
        enabled: false,
      },
      dataRows: [],
    }
  },
  computed: {
    ...mapGetters({
      graduationRegisterSubjects: 'registerGraduation/graduationRegisterSubjects',
      totalRows: 'registerGraduation/totalRows',
      graduationRegisterSubjectById: 'registerGraduation/graduationRegisterSubjectById',
      years: 'registerGraduation/years',
      graduationRegisterRoundCurrent: 'registerGraduation/graduationRegisterRound',
    }),
    matchingYearId() {
      const currentYearStr = this.currentYear.toString();
      const matchingYear = this.years.find(item =>
        item.code.slice(0, 4) === currentYearStr
      );
      return matchingYear ? matchingYear.id : null;
    },
  },
  async created() {
    this.filter.studentClassId = this.user.programmes[0].studentClassId
    this.filter.programmeId = this.user.programmes[0].id
    this.trainingSystemId = this.user.programmes[0].trainingSystemId
    await this.getGraduationRegisterRoundCurrent({
      trainingSystemId: this.trainingSystemId,
      yearId: this.matchingYearId
    })
    await this.getDataSourcesFromStore()
  },
  methods: {
    ...mapActions({
      getGraduationRegisterRoundCurrent: 'registerGraduation/getGraduationRegisterRound',
      getGraduationRegisterSubjects: 'registerGraduation/getGraduationRegisterSubjects',
      getGraduationRegisterSubjectById: 'registerGraduation/getGraduationRegisterSubjectById',
      updateSubjectGraduation: 'registerGraduation/updateSubjectGraduation',
      updateScoreGraduation: 'registerGraduation/updateScoreGraduation'
    }),
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getGraduationRegisterSubjects(this.filter),
          this.getGraduationRegisterSubjectById({
            studentClassId: this.filter.studentClassId,
            graduationRegisterRoundId: this.graduationRegisterRoundCurrent.id,
          }),
        ])
        this.dataRows = this.graduationRegisterSubjects
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.dataRows.length; i++) {
          const row = this.dataRows[i]
          this.totalWeightedPoints += row.averagePoint * row.totalCreditNum
          this.totalCredits += row.totalCreditNum
        }
        this.totalAveragePoint = this.graduationRegisterSubjectById.scoreGraduation ?  this.graduationRegisterSubjectById.scoreGraduation :
          (this.totalCredits > 0 ? (this.totalWeightedPoints / this.totalCredits).toFixed(2) : '0.00')
        
        if (!this.graduationRegisterSubjectById.scoreGraduation) {
          const param = {
            graduationRegisterStudentId: this.graduationRegisterSubjectById.id,
            scoreGraduation: this.totalAveragePoint
          }
          try {
            const response = await this.updateScoreGraduation(param)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getDataSourcesFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDeleteSubject(data) {
      let avgPointCurrent = data.averagePoint
      let creditNumCurrent = data.totalCreditNum
      this.totalWeightedPoints = this.totalWeightedPoints - creditNumCurrent
      this.totalAveragePoint = ((this.totalAveragePoint * this.totalWeightedPoints - avgPointCurrent * creditNumCurrent) / (this.totalWeightedPoints - creditNumCurrent)).toFixed(2);
      const param = {
        statusGraduation: 0,
        studentCreditClassId: data.id,
        graduationRegisterStudentId: this.graduationRegisterSubjectById.id,
        scoreGraduation: this.totalAveragePoint
      }
      try {
        const response = await this.updateSubjectGraduation(param)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getDataSourcesFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onAddSubject(data) {
      let avgPointCurrent = data.averagePoint
      let creditNumCurrent = data.totalCreditNum
      this.totalWeightedPoints = this.totalWeightedPoints + creditNumCurrent
      this.totalAveragePoint = ((this.totalAveragePoint * this.totalWeightedPoints + avgPointCurrent * creditNumCurrent) / (this.totalWeightedPoints + creditNumCurrent)).toFixed(2);
      const param = {
        statusGraduation: null,
        studentCreditClassId: data.id,
        graduationRegisterStudentId: this.graduationRegisterSubjectById.id,
        scoreGraduation: this.totalAveragePoint
      }
      try {
        const response = await this.updateSubjectGraduation(param)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getDataSourcesFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.list-schedule ul li {
  list-style: none;
  padding: 2px;
}
.list-schedule ul {
  padding: 0;
}
</style>
