<template>
  <section id="card-actions">
    <b-row>
      <b-col cols="12">
        
        <b-card-text
          v-if="isAllowRegister"
          class="mb-2 text-xl-center text-danger"
          style="font-size: 23px"
        >
          Thời gian đăng ký {{ graduationRegisterRoundCurrent.startDate }} - {{ graduationRegisterRoundCurrent.endDate }}
        </b-card-text>
        
        <b-card-text
          v-else
          class="mb-2 text-xl-center text-muted"
          style="font-size: 23px"
        >
          Đã hết thời gian đăng ký {{ graduationRegisterRoundCurrent.startDate }} - {{ graduationRegisterRoundCurrent.endDate }}
        </b-card-text>
        
        <b-card-actions
          ref="cardAction"
          title="THÔNG TIN HỒ SƠ TỐT NGHIỆP"
          :no-actions="true"
        >
          <b-row>
            <b-col cols="12">
              <b-table
                responsive
                :items="items"
                bordered
              >
                <template
                  #cell(Trạng_thái)="data"
                  class="text-center"
                >
                  <div class="text-center">
                    <feather-icon :icon="data.value" />
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card-actions>
        <b-button
          v-if="checkRegister"
          size="sm"
          variant="success"
          @click="onRegisterGraduation"
        >
          <span class="mr-25 align-middle">Đăng ký xét tốt nghiệp</span>
        </b-button>
        <student-subject-group v-if="!checkRegister" :no-actions="checkRegisterSubject" />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow, BCol, BTable, BCardText,BButton
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment/moment'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { STATUSES_GRADUATION_REGISTER } from '@/const/status'
import StudentSubjectGroup from '@/views/register-graduation/StudentSubjectGroup.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardText,
    StudentSubjectGroup,
  },
  data() {
    return {
      isLoading: false,
      currentUser: getUser(),
      isAllowRegister: false,
      trainingSystemId: null,
      studentClassId: null,
      currentYear: new Date().getFullYear(),
      checkStudentGraduation: false,
      checkStudentHasQPAN: false,
      checkRegister: false,
      checkRegisterSubject: false,
      fields: [
        'STT',
        'Danh_mục',
        { key: 'Trạng_thái', label: 'Trạng_thái' },
        'Ghi_chú',
      ],
      items: [
        { STT: '1', Danh_mục: 'Tích lũy đủ số tín chỉ', Trạng_thái: '', Ghi_chú: 'Sinh viên cần kiểm tra số tín chỉ tích lũy các học phần đúng theo CTĐT trước khi đăng ký hồ sơ xét tốt nghiệp' },
        { STT: '2', Danh_mục: 'Điểm trung bình tích lũy toàn khóa học theo thang điểm 4.0 đạt từ 2.0 trở lên', Trạng_thái: '', Ghi_chú: '' },
        { STT: '3', Danh_mục: 'Không bị kỷ luật ở mức đình chỉ có thời hạn trở lên hoặc bị truy cứu trách nhiệm hình sự', Trạng_thái: '', Ghi_chú: '' },
        { STT: '4', Danh_mục: 'Có chứng chỉ Giáo dục Quốc phòng & An ninh', Trạng_thái: '', Ghi_chú: '' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      years: 'registerGraduation/years',
      graduationRegisterRoundCurrent: 'registerGraduation/graduationRegisterRound',
    }),
    matchingYearId() {
      const currentYearStr = this.currentYear.toString();
      const matchingYear = this.years.find(item =>
        item.code.slice(0, 4) === currentYearStr
      );
      return matchingYear ? matchingYear.id : null;
    },
  },
  async created() {
    this.trainingSystemId = this.currentUser.programmes[0].trainingSystemId
    this.studentClassId = this.currentUser.programmes[0].studentClassId
    await this.getYears()
    await this.getGraduationRegisterRoundCurrent({
      trainingSystemId: this.trainingSystemId,
      yearId: this.matchingYearId
    })
    const openDate = +moment(this.graduationRegisterRoundCurrent.startDate, 'DD/MM/YYYY HH:mm')
    const closeDate = +moment(this.graduationRegisterRoundCurrent.endDate, 'DD/MM/YYYY HH:mm')
    const intTimeCurren = +moment()
    if (openDate <= intTimeCurren && closeDate >= intTimeCurren) {
      this.isAllowRegister = true
    }
    await this.getDataSourcesFromStore()
  },
  methods: {
    ...mapActions({
      getYears: 'registerGraduation/getYears',
      getGraduationRegisterRoundCurrent: 'registerGraduation/getGraduationRegisterRound',
      getCheckStudentGraduation: 'registerGraduation/checkStudentGraduation',
      getCheckStudentHasQPAN: 'registerGraduation/checkStudentHasQPAN',
      getCheckGraduationStudent: 'registerGraduation/checkGraduationStudent',
      createGraduationRegisterStudent: 'registerGraduation/createGraduationRegisterStudent',
    }),
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        const [response1, response2] = await Promise.all([
          this.getCheckStudentGraduation(this.studentClassId),
          this.getCheckStudentHasQPAN(this.studentClassId),
        ])
        this.checkStudentGraduation = response1.check
        this.checkStudentHasQPAN = response2.check
        this.items[0].Trạng_thái = this.checkStudentGraduation ? 'CheckIcon' : 'XIcon'
        this.items[1].Trạng_thái = this.checkStudentGraduation ? 'CheckIcon' : 'XIcon'
        this.items[2].Trạng_thái = this.checkStudentGraduation ? 'CheckIcon' : 'XIcon'
        this.items[3].Trạng_thái = this.checkStudentHasQPAN ? 'CheckIcon' : 'XIcon'
        if (this.checkStudentHasQPAN && this.checkStudentGraduation && this.isAllowRegister) {
          this.checkRegister = true
        }
        const response3 = await this.getCheckGraduationStudent({
          studentClassId: this.studentClassId,
          graduationRegisterRoundId: this.graduationRegisterRoundCurrent.id,
        })
        if (response3.check) {
          this.checkRegister = false
          if (this.checkStudentHasQPAN && this.checkStudentGraduation && this.isAllowRegister) {
            this.checkRegisterSubject = true
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onRegisterGraduation() {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xét tốt nghiệp?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.createGraduationRegisterStudent({
              studentClassId: this.studentClassId,
              graduationRegisterRoundId: this.graduationRegisterRoundCurrent.id,
              status: 0
            })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>
